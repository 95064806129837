// TODO review how this is being used, don't instantiate in every service...
export class AdminConfig {
  baseUrl: string; // api
  baseCDNRUrl: string; // cdnr
  baseLabUrl: string; // lab
  apiKey: string; // apikey

  constructor() {
    this.baseUrl = 'https://admin-api-staging.novaseekresearch.com/admin/'; // admin API url
    this.baseCDNRUrl = 'https://cdnr-staging.novaseekresearch.com/'; // cdnr front-end url, for impersonating a user
    this.baseLabUrl = 'https://lab-staging.novaseekresearch.com/'; // lab front-end url, for impersonating a user
    this.apiKey = 'testonly';
  }
}
